import { AfterViewInit, Component,Input,OnDestroy,OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { LoginModel } from './login.model';
import { CBrowserDialog } from '../shared/compatablebrowser/cbrowser.dialog';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '../service/userprofile/account.service';
import { AuthServerProvider } from '../service/userprofile/auth-session.service';
import { SharedModule } from '../shared/shared.module';
import { Account } from '../service/model/account.model';
import { map } from 'rxjs';

export class ErrorMessage{
  error: string ='';
  message: string ='';
  status: number = 0
}

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  imports: [RouterModule, SharedModule, FormsModule],
})
export class LoginComponent  implements OnInit, OnDestroy , AfterViewInit{
  vm : LoginModel = new LoginModel();
  
  
  isLoading: boolean = false;

  timeout: string='';

 // @Input() timeout?: string; // @Input('timeout') timeout?: string;
  //timeout = this.activatedRoute.queryParams.pipe(map((queryParams) => queryParams['timeout'])); 
    
  constructor(private accountService: AccountService, private authService: AuthServerProvider, 
    private router: Router,private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
  }
      
  ngOnInit() {
    //this.isTimeout = this.activatedRoute.snapshot.paramMap.get('timeout');
    // if already authenticated then navigate to home page
    this.accountService.getAuthenticationState().subscribe((account) => {
      if (this.accountService.isAuthenticated()) {
        this.handleAccountForHome(account);
      }
    });
  }

  ngAfterViewInit(): void {
    //this.username.nativeElement.focus();
    //this.isTimeout = this.activatedRoute.snapshot.paramMap.get('timeout');
    // this.activatedRoute.queryParams.subscribe(params => { 
    //   if(this.vm.errorUserPassBlank == null && this.vm.errorUserPassBlank == null && this.vm.errorUserPassBlank == null && this.vm.errorUserPassBlank == null 
    //     && this.vm.errorUserPassBlank == null ){
    //       this.timeout = params['timeout']; 
    //   }      
    // });
    
    
    this.timeout = null;
      if(this.accountService.getIsLogedout() && this.accountService.getLogoutError() == null) { 
        this.timeout = 'true';
        this.accountService.setIsLogedout(false);
      }else{
        if(this.accountService.getLogoutError() === 'errorUserPassBlank'){
          this.vm.errorUserPassBlank = 'ERROR';
        } else if(this.accountService.getLogoutError() === 'errorUserAccountLocked'){
          this.vm.errorUserAccountLocked = 'ERROR';
        } else if(this.accountService.getLogoutError() === 'errorUserNotActivated'){
          this.vm.errorUserNotActivated = 'ERROR';
        } else if(this.accountService.getLogoutError() === 'errorUserPassNotExists'){
          this.vm.errorUserPassNotExists = 'ERROR';
        } 
        this.accountService.setLogoutError(null);
      }
  }

  ngOnDestroy(){
  }

  username : string = null;
  password : string = null; 
  processlogin(form: NgForm){   
    this.username = form.value.username;
    this.password = form.value.password;
    this.isLoading = true;

    if ((this.password == '' || this.password == undefined) || (this.username == '' || this.username == undefined)) {
      this.vm.errorUserPassBlank = "ERROR";
      this.vm.errorUserAccountLocked = null;
      this.vm.errorUserNotActivated = null;
      this.vm.errorUserPassNotExists = null;
      this.vm.errorUserInactive = null;
      this.isLoading = false;
    }  else {
      this.authService.loginUser(this.username,this.password)
        .subscribe({
          next: (response: Account) => {            
            this.handleAccountForHome(response);
            this.isLoading = false;
            this.accountService.setRedirectedToLogin('true');
          },
          error: (errorRes: HttpErrorResponse) => {
            this.handleLogInError(errorRes);
            this.timeout = null;
            this.accountService.setIsLogedout(false);
            /////call all logout THNigs ????
            this.isLoading = false;
          },
        });
    }  
  } 

  handleAccountForHome(authModel: Account) {
    this.vm.account = authModel.authorities;
    if(authModel != null){
        if (authModel.authorities.includes('ROLE_TITLE_COMPANY_USER') ) {
          this.router.navigate(['/quotequeue']);
        } else if(authModel.authorities.includes('ROLE_LAW_FIRM_USER')){
          this.router.navigate(['/lfquotequeue']);
        } else if (authModel.authorities.includes('ROLE_MSD_ADMIN') || authModel.authorities.includes('ROLE_MSD_VIEWER') 
                    || authModel.authorities.includes('ROLE_MSD_PROCESSOR')) {
            if (authModel.pendingApproval && authModel.authorities.includes('ROLE_MSD_ADMIN')) {
               this.router.navigate(['/usermanagement']);
            } else {
                this.router.navigate(['/msdarquotequeue']);
            }
        } else if (authModel.authorities.includes('ROLE_LAW_FIRM_ADMIN')) {
            if (authModel.pendingApproval) {
                this.router.navigate(['/usermanagement']);
            } else {
                this.router.navigate(['/lfquotequeue']);
            }
        } else {
            this.router.navigate(['/quotequeue']);
        }
    }
  }
    
  handleIdentityInError(error: HttpErrorResponse) {
    
  }

  handleLogInError(error: HttpErrorResponse) {
    //let errors = error as ErrorMessage;
    
    this.vm.errorUserPassBlank = null;   
    if(error.error != undefined){
      let errors = JSON.stringify(error.error);
      if (errors.indexOf('not activated') > -1) {
          this.vm.errorUserNotActivated = 'ERROR';
          this.accountService.setLogoutError('errorUserNotActivated');
          this.vm.errorUserInactive = null;
          this.vm.errorUserAccountLocked = null;
          this.vm.errorUserPassNotExists = null;
      } else if (errors.indexOf('inactive') > -1) {
          this.vm.errorUserInactive = 'ERROR';
          this.accountService.setLogoutError('errorUserInactive');
          this.vm.errorUserNotActivated = null;
          this.vm.errorUserAccountLocked = null;
          this.vm.errorUserPassNotExists = null;
      } else if (errors.indexOf('locked') > -1) {
          this.vm.errorUserAccountLocked = 'ERROR';
          this.accountService.setLogoutError('errorUserAccountLocked');
          this.vm.errorUserNotActivated = null;
          this.vm.errorUserInactive = null;
          this.vm.errorUserPassNotExists = null;
      } else {
        this.vm.errorUserNotActivated = null;
        this.vm.errorUserInactive = null;
        this.vm.errorUserAccountLocked = null;
        this.vm.errorUserPassNotExists = 'ERROR';
        this.accountService.setLogoutError('errorUserPassNotExists');
      }
    } else {
      let errors = JSON.stringify(error.message);
      if (errors.indexOf('not activated') > -1) {
        this.vm.errorUserNotActivated = 'ERROR';
        this.accountService.setLogoutError('errorUserNotActivated');
        this.vm.errorUserInactive = null;
        this.vm.errorUserAccountLocked = null;
        this.vm.errorUserPassNotExists = null;
      } else if (errors.indexOf('inactive') > -1) {
        this.vm.errorUserInactive = 'ERROR';
        this.accountService.setLogoutError('errorUserInactive');
        this.vm.errorUserNotActivated = null;
        this.vm.errorUserAccountLocked = null;
        this.vm.errorUserPassNotExists = null;
      } else if (errors.indexOf('locked') > -1) {
        this.vm.errorUserAccountLocked = 'ERROR';
        this.accountService.setLogoutError('errorUserAccountLocked');
        this.vm.errorUserNotActivated = null;
        this.vm.errorUserInactive = null;
        this.vm.errorUserPassNotExists = null;
      //} else if (error.error.message.indexOf('expired') > -1) {
        //$state.go('password');
      }
      else {
        this.vm.errorUserNotActivated = null;
        this.vm.errorUserInactive = null;
        this.vm.errorUserAccountLocked = null;
        this.vm.errorUserPassNotExists = 'ERROR';
        this.accountService.setLogoutError('errorUserPassNotExists');
      }
    }

  }

  

   /*Used to toggle password visibility by switching between "password" and "text" type.*/
  hideShowPassword() {

    if (this.vm.passwordFieldInputType == 'password') {
        this.vm.passwordFieldInputType = 'text';
        this.vm.passwordFieldInputTypeBool = true;
    }
    else {
        this.vm.passwordFieldInputType = 'password';
        this.vm.passwordFieldInputTypeBool = false;
    }
  }

 /*Retrieve user name from cookie and set remember-me flag and password value*/
/*checkRemeber() {
  var cookiePassword = this.getCookie('vm-rememberMe-' + this.username);
  if (cookiePassword != '' && cookiePassword != undefined) {
      this.password = cookiePassword;
      this.vm.rememberMe = true;
  }
}*/
  /*Sets cookie for given time span.*/
/*setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}*/

/*Return cookie according to cookie name.*/
/*getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}*/
openCBrowserDialog() {
  const dialogRef = this.dialog.open(CBrowserDialog);
  dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
  });
}  

}
