export class LoginModel{
    account: {}
    isAuthenticated: boolean = false;
   
    login: any;
    register: any;
    rememberMe: boolean = true;
    success: string = null;
    errorUserPassBlank: String = null;
    errorUserPassNotExists: string = null;
    errorUserAccountLocked: string = null;
    errorUserNotActivated: String = null;
    errorUserInactive: string = null;
    roles: string = '';
    hideShowPassword: any
    passwordFieldInputType: string = 'password';
    passwordFieldInputTypeBool: boolean  = false;  
}