<div class="container-fluid backFix" style="margin: 0 auto -105px; height: auto!important; min-height: 100%; ">
  <div class="row">
      <nav class="navbar" role="navigation">
        <router-outlet name="navbar"></router-outlet>
      </nav>
  </div>
  <div class="overlay">
    <div class="center" *ngIf="loading" >
      <mat-progress-spinner diameter=80 mode="indeterminate" style='margin-Left:50%;height:400px;z-index:10;position:fixed;'>
      </mat-progress-spinner> 
    </div>
  </div>
  <div *ngIf="loading">
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
  </div>
  <div>      
    <router-outlet></router-outlet>
  </div>
  
</div>
<footer class="footer stickyFooter text-center">
  <div class="col-md-12">
      <div class="row footerPre">
          <div class="col-md-12 col-sm-12 col-lg-12">
              <span class="msdFooter-text text-center">If you have any questions, please
              contact us at (314)-768-6235</span>
          </div>
      </div>
      <div class="row footerPost">
          <div class="col-md-4 col-sm-12 col-lg-4 col-md-push-2 text-center">
              <div class="footer-copy">&copy;2020 Metropolitan St. Louis Sewer District All Rights Reserved</div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 col-md-push-2">
              <div class="footer-links pull-right">
                  <a href="https://portal.laserfiche.com/Portal/DocView.aspx?id=441550&repo=r-a96260ce" target="_blank">User Guide</a>
                  <a href="#" (click)="$event.preventDefault(); openPPDialog()">Privacy Policy</a>
                  <a href="#" (click)="$event.preventDefault(); openTUDialog()">Terms of Use</a>
                  <a href="mailto:titlequote@stlmsd.com?Subject=Contact%20us">Contact Us</a>
              </div>
          </div>
      </div>
  </div>
</footer>