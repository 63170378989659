import { Component, OnDestroy, OnInit } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import HasAnyAuthorityDirective from '../shared/directive/has-any-authority.directive';
import { SharedModule } from '../shared/shared.module';
import { TQEntityService, TQMessage } from '../service/tq/tqentity.service';
import { AuthServerProvider } from '../service/userprofile/auth-session.service';
import { AccountService } from '../service/userprofile/account.service';
import { Reports } from '../service/model/tqquote.model';
import { Account, ProfileInfo } from '../service/model/account.model';


@Component({
  standalone: true,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  imports: [RouterModule, SharedModule, HasAnyAuthorityDirective],
})
export class NavbarComponent implements OnInit {

  profileEnv: string ;
  inProduction: boolean
  swaggerEnabled: boolean ;
  reportLinks: any ;
  item = {};
  isNavbarCollapsed = true;
  account: Account = null;
  tQMessages: TQMessage[];

  constructor(private entityService: TQEntityService, private accountService: AccountService,
    private authService: AuthServerProvider ,private router: Router) {
  }

  ngOnInit() {
    this.entityService.getReportsUrl()
    .subscribe(reportsUrl =>{
      this.hangleReportsUrl(reportsUrl);
    });

    this.accountService.getProfileInfo()
    .subscribe(profile =>{
      this.handleProfileInfo(profile);
    });

    this.accountService.getAuthenticationState()
    .subscribe(response => {     
        this.account = response;     
    });

    this.entityService.getTQMessageValid()
    .subscribe(messages =>{
      this.tQMessages = messages;
    });
  }
  
  hangleReportsUrl(reportsUrl: Reports){     
    this.reportLinks = {
      sectionOne:[
          {
              url: reportsUrl.pendingCourtDate,
              title: "Pending Court Date"
          },
          {
              url: reportsUrl.quotesOnSummaryAccounts,
              title: "Quotes on Summary Accounts"
          },
          {
              url: reportsUrl.refinanceQuotesPAHold,
              title: "Refinance Quotes with PA Hold"
          },
          {
              url: reportsUrl.pendingCloseOverdure,
              title: "Pending Close Overdue"
          },
          {
              url: reportsUrl.incorrectSellerReport,
              title: "Incorrect Seller Report"
          },
          {
              url: reportsUrl.quotePaymentDelinquent,
              title: "Quote Payment Delinquent"
          },
          {
              url: reportsUrl.quotesAdjustmentsByMSDUser,
              title: "Quote Adjustments by MSD User"
          },
          {
              url: reportsUrl.transFailureReport,
              title: "Transaction Failure Report"
          },
          {
              url: reportsUrl.buyerMailingAddress,
              title: "Buyers Mailing Address"
          },
          {
              url: reportsUrl.judgementSatisfactionReport,
              title: "Judgement Satisfaction Report"
          },
          {
              url: reportsUrl.collectionHoldReport,
              title: "Collection Hold Report"
          }
      ],
      sectionTwo:[
            {
              url:"/usage",
              title: "Quote Usage Details"
          },
          {
              url:"/quotesummary",
              title: "Quote Summary"
          }
      ] 
    }; 
  }   

  handleProfileInfo(profile:ProfileInfo){    
      //var response: any = {};
      //response.activeProfiles = profile.activeProfiles;
      this.profileEnv = profile.ribbonEnv;
      if (this.profileEnv == "dev") {
        this.profileEnv = "Dev - Environment";
      } else if(this.profileEnv == "test" || profile.activeProfiles[0] == "test"){
        this.profileEnv = "Test-UAT - Environment";
      }
      this.inProduction = profile.activeProfiles.indexOf("prod") !== -1;
      this.swaggerEnabled = profile.activeProfiles.indexOf("swagger") !== -1;      
  }
  
    

  /*Close currently open navbar submenus and logout user*/
  logout() {
      this.collapseNavbar();
      this.authService.logout()
        .subscribe( {
          next: (response: any) => {
            this.setCookie('vm-SearchAllUser', null, null);
            //QuotequeueUpdateCheck.setUpdateMode(false);
            this.router.navigate(['/login']);
          },
          error: (errorRes: HttpErrorResponse) => {
            //this.handleIdentityInError(errorRes);
          },   
         });      
  }

  
   /*Sets cookie with given time span*/
  setCookie(cname: any, cvalue: any, exdays: any) {
    var d = new Date();
    //let d = new Date['toGMTString']();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  /*Toggle navbar submenu*/
  toggleNavbar() {
      this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  /*Hide the navbar submenu*/
  collapseNavbar() {
      // this.isNavbarCollapsed = true;
      // QuotequeueUpdateCheck.setUpdateMode(false);
  }
  
  collapseNav(page: any) {
      // var isUpdateMode = QuotequeueUpdateCheck.isUpdateMode();
      // var confirm;
      //    if(page=="quotequeue" && !isUpdateMode){
      //         $state.go(page, {}, { reload: true });
      //    }else if (page=="msdARquotequeue" && !isUpdateMode){
      //         $state.go(page, {}, { reload: true });
      //    }else if(page=="LFquotequeue" && !isUpdateMode){
      //         $state.go(page, {}, { reload: true });
      //    }
      //    if(isUpdateMode){
      //     confirm = $mdDialog.confirm({
      //        title: 'Please Confirm',
      //        textContent: 'You have unsaved changes on this page. Do you want to leave this page?',
      //        cancel: 'No',
      //        ok: 'Yes'
      //     });
      //     $mdDialog.show(confirm).then(function () {
      //     $state.go(page, {}, { reload: true });
      //        });
      //    }
      //    vm.isNavbarCollapsed = true;
  }
}

