import {Component} from '@angular/core';

@Component({
  selector: 'dialog-termofuse',
  templateUrl: './termofuse.dialog.html',
})
export class TermofuseDialog  {
}






