import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Reports, TQMSDEntity, TQValue } from "../model/tqquote.model";
import { Observable, shareReplay } from "rxjs";

export interface TQDisclaimer {
    id: number
    name: string
    title: string
    message: string;
    futureCloseAllowedDate : any;
    hoursForLawFirmCompletetion : any;
}

export interface TQMessage
{
    id: number;
    messageText:string;
    startDate:string;
    endDate:string;
}



@Injectable({ providedIn: 'root' })
export class TQEntityService {

    private reports$?: Observable<Reports>;
    private tqMessageValid$?: Observable<TQMessage[]>;
    private disclaimersRep$?: Observable<TQDisclaimer[]>;
    private allDisclaimers$?: Observable<TQDisclaimer[]>;
    private partialTQValues$: Observable<TQValue[]>;
    private allTQValues$: Observable<TQValue[]>;


    validMessages: TQMessage[] = null;
    tqCompanies: TQMSDEntity[] = null;

    constructor(private http: HttpClient){}

    //from titlequote
    getTqDisclaimersReport() : Observable<TQDisclaimer[]>  {
        if (this.disclaimersRep$) {
            return this.disclaimersRep$;
        }
        this.disclaimersRep$ =  this.http.get<TQDisclaimer[]>('api/reports-url',{} )
        .pipe(
           shareReplay()
        )
        return this.disclaimersRep$;
    }

    getAllTqDisclaimers() : Observable<TQDisclaimer[]>  {
        if (this.allDisclaimers$) {
            return this.allDisclaimers$;
        }
        this.allDisclaimers$ =  this.http.get<TQDisclaimer[]>('api/t-q-disclaimers',{} )
        .pipe(
           shareReplay()
        )
        return this.allDisclaimers$;
    }
    getTQMessageValid(): Observable<TQMessage[]> {
        const url =  'api/valid-messages';
        if (this.tqMessageValid$) {
            return this.tqMessageValid$;
        }
        this.tqMessageValid$ =  this.http.get<TQMessage[]>(url,{} )
        .pipe(
           shareReplay()
        )
        return this.tqMessageValid$;
    }




    getPrtialTqValue() {
        if (this.partialTQValues$) {
            this.partialTQValues$ = undefined;
        }
        if (this.partialTQValues$ === undefined || this.partialTQValues$ == null) {
            this.partialTQValues$ = this.http.get<TQValue[]>('api/t-q-values?page='+0+'&size='+50, {});
            // .pipe(
            //     //
            // )
        }
        return  this.partialTQValues$;
    }
    //emd titlequote

//     getResponseForUrl(url: string) : any{
//         return   this.http.get<any>(url,{})
//     }

//     getResponseForUrlWithHeader(url: string) : any{
//         return   this.http.get<any>(url,{observe: 'response'})
//     }

    // getTQMessageValidById() : TQMessage[]{

    //     if (this.validMessages === undefined || this.validMessages == null) {
    //         this.http.get<TQMessage[]>('api/t-q-disclaimers/:id',{})
    //         .pipe(
    //              data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
    //             data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
    //         ).subscribe(data => {
    //             return (data as TQMessage[] );
    //         });
    //     }
    //     return this.validMessages;
    // }

    //from registration
    getCompanies(tqvalue: string){
       const url =  'api/t-qmsd-entities/tqvalue/'+ tqvalue;
        return this.http.get<TQMSDEntity[]>(url,{} );//{params: queryParams});
    }


    //from NAv bar
    getReportsUrl(): Observable<Reports> {
        const url =  'api/reports-url';
        if (this.reports$) {
            return this.reports$;
        }
        this.reports$ =  this.http.get<Reports>(url,{} )
        .pipe(
            // data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
           // data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
           shareReplay()
        )
        return this.reports$;
    }





}
