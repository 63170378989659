import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthServerProvider } from '../../service/userprofile/auth-session.service';
import { StateStorageService } from '../../service/userprofile/state-storage.service';
import { AccountService } from '../../service/userprofile/account.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private authServerProvider: AuthServerProvider,
    private stateStorageService: StateStorageService,
    private router: Router,
    private dialog: MatDialog,
    private accountService: AccountService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        tap({
          error: (err: HttpErrorResponse) => {
            if (err.status === 401 && err.url && !err.url.includes('api/account') && this.accountService.isAuthenticated()) {
              this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
              this.authServerProvider.logout();
              this.accountService.setIsLogedout(true);
              this.router.navigate(['/login']);
            }else if (err.status === 401 && err.url && !err.url.includes('api/account') && !this.accountService.isAuthenticated()) {
              this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
              this.authServerProvider.logout();
              this.accountService.setIsLogedout(true);
              this.router.navigate(['/login']);
            }else if ( ( err.status === 403 || err.status === 400) && err.url && !err.url.includes('api/account') && this.accountService.isAuthenticated()) {
              this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
              this.authServerProvider.logout();
              this.accountService.setIsLogedout(true);
              this.router.navigate(['/login']);
            }
            // }else if(err.status === 504){
            //   this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
            //   this.authServerProvider.logout();
            //   this.accountService.setIsLogedout(true);
            //   this.router.navigate(['/login']);
            // }
          },
        })
      );
  }






  // function afterCSRFRenewed(oldResponse) {
  //   if (getCSRF() !== '') {
  //       // retry the old request after the new CSRF-TOKEN is obtained
  //       var $http = $injector.get('$http');
  //       return $http(oldResponse.config);
  //   } else {
  //       // unlikely get here but reject with the old response any way and avoid infinite loop
  //       return $q.reject(oldResponse);
  //   }
  // }
}
