import { Component } from '@angular/core';
import { TermofuseDialog } from './shared/termofuse/termofuse.dialog';
import { MatDialog} from '@angular/material/dialog';
import { LoadingService } from './service/util/spinner.service';
import { delay } from 'rxjs';
import { PrivatePolicyDialog } from './shared/privatepolicy/privatepolicy.dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'titlequote';
  loading: boolean = false;
  constructor( public dialog: MatDialog, private _loading: LoadingService) {}

  ngOnInit() {
    this.listenToLoading();
  }

  openTUDialog() {
    const dialogRef = this.dialog.open(TermofuseDialog);
    
    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
    });
  }
  openPPDialog() {
    const dialogRef = this.dialog.open(PrivatePolicyDialog);
    
    dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
    });
  }


  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  // onActivate(event: any) {
  //   let scrollToTop = window.setInterval(() => {
  //       let pos = window.scrollY;
  //       if (pos > 0) {
  //           window.scrollTo(0, pos - 20); // how far to scroll on each step
  //       } else {
  //           window.clearInterval(scrollToTop);
  //       }
  //   }, 16);
  // }
}
