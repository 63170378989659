import {Component} from '@angular/core';

@Component({
  selector: 'privatepolicy-termofuse',
  templateUrl: './privatepolicy.dialog.html',
})
export class PrivatePolicyDialog  {
}






