<div class="modal-header">
    <button type="button" class="close"  aria-hidden="true" mat-button mat-dialog-close>&times;</button>
    <h4 class="modal-title"><b>METROPOLITAN ST. LOUIS SEWER DISTRICT - PRIVACY POLICY</b></h4>
</div>
<div class="modal-body" style="height:500px;overflow:auto;">
    <p><b>PUBLIC WEBSITE PRIVACY POLICY</b></p>
    <p>Your privacy is important to us.  State laws and good business practices prevent us from routinely redistributing personal information that you may send to us, via e-mail or by completing online applications or forms.</p>
    <p><b>INFORMATION WE COLLECT</b></p>
    <p>In order to complete any online transactions available on this site, we may collect information from you, including your email address, your postal address, identification numbers, or other pieces of personal information.</p>
    <p>Online forms indicate the information that is required and the information that is optional-you do not have to complete any "optional" fields on a form.  Also, if you do not choose to submit information via our website, we will gladly complete your transaction in person rather than online.</p>
    <p><b>SAFEGUARDING AND COLLECTING INFORMATION</b></p>
    <p>The Metropolitan St. Louis Sewer District takes reasonable measures to safeguard the information you transmit to us through online applications and forms.</p>
    <p>These measures include encryption technology which allows the District to protect personal information transmitted over the Internet using security protocols.  The information we collect for online transactions will not be shared with third persons unless there is a legal reason to do so.</p>
    <p><b>EMAIL ADDRESSES</b></p>
    <p>In the course of normal business, the District may request and obtain your email address.  These email addresses are not redistributed to third parties, and are used only to respond to your inquiry or request.  If for some reason the input of another agency is required in order to complete your request, you will be notified, and your email message may be forwarded to that agency with your permission.  The District's privacy policy does not extend to any other agencies, and it is up to you to determine the privacy policies of that other agency.</p>
    
    <p>Email addresses may be gathered and aggregated in order to determine certain demographical information, but the District's purpose is not to isolate or identify any particular individuals.</p>
    <p><b>OTHER INCIDENTAL INFORMATION</b></p>
    <p>In the course of normal business, the District may obtain and analyze other information, such as a customer's IP (network) address, browser type, pages and files requested from our services, and so on.  This information is used only for statistical analysis, and again, it is not the District's purpose to isolate or identify any particular individuals.</p>
    <p><b>COOKIES</b></p>
    <p>From time to time, the District may employ cookies in order to facilitate an online transaction.  You have the right to refuse the cookie and the online transaction, and to complete your transaction in person at the District's Offices.</p>
    <p><b>MISSOURI SUNSHINE LAW</b></p>
    <p>As a state governmental agency, the Metropolitan St. Louis Sewer District is subject to Chapter 610 of the Missouri Revised Statutes, commonly known as the Missouri Sunshine Law.  If an appropriate request is made to the District for information that is subject to disclosure under the Sunshine Law, the District is required to disclose the information requested, and this may include your personally identifiable information.</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default"  mat-button mat-dialog-close> <span>Close</span></button>
</div>