import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '', component: NavbarComponent, outlet: 'navbar',
  },  
  { 
    path: '', component: LoginComponent 
  }, 
  { 
    path: 'login', component: LoginComponent 
  },
  { 
    path: 'account',
    loadChildren: () => import('./account/account.route'),
  },
  { 
    path: 'quotequeue',
    loadComponent: () => import('./titlequote/quotequeue/quotequeue.component')
      .then(c => c.QuotequeueComponent)
  },
  { 
    path: 'lfquotequeue',
    loadComponent: () => import('./titlequote/lawfirmquotequeue/lawfirmquotequeue.component')
      .then(c => c.LawfirmquotequeueComponent)
  },
  { 
    path: 'msdarquotequeue',
    loadComponent: () => import('./titlequote/msdarquotequeue/msdarquotequeue.component')
      .then(c => c.MsdarquotequeueComponent)
  },
  { 
    path: 'profile',
    loadComponent: () => import('./account/profile/profile.component')
      .then(c => c.ProfileComponent)
  },
  { 
    path: 'usermanagement',
    loadComponent: () => import('./admin/user/usermanagement.component')
      .then(c => c.UserManagementComponent)
  },
  { 
    path: 'lawfirmadmin',
    loadComponent: () => import('./admin/lawfirm/lawfirmadmin.component')
      .then(c => c.LawfirmadminComponent)
  },
  { 
    path: 'systemmessages',
    loadComponent: () => import('./admin/systemmessage/systemmessage.component')
      .then(c => c.SystemmessageComponent)
  },
  { 
    path: 'quotesummary',
    loadComponent: () => import('./admin/report/quotesummary.component')
      .then(c => c.QuoteSummaryComponent)
  },
  { 
    path: 'usage',
    loadComponent: () => import('./admin/report/usage.component')
      .then(c => c.UsageComponent)
  }
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
