import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { TermofuseDialog } from './shared/termofuse/termofuse.dialog';
import { CBrowserDialog } from './shared/compatablebrowser/cbrowser.dialog';
import { PrivatePolicyDialog } from './shared/privatepolicy/privatepolicy.dialog';
import { httpInterceptorProviders } from './shared/interceptor/index';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
@NgModule({
  declarations: [
    AppComponent,
    TermofuseDialog,
    CBrowserDialog,
    PrivatePolicyDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule ,
    HttpClientXsrfModule,NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    })  
  ],
  providers: [
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
