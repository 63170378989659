import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';



import { StateStorageService } from '../../service/userprofile/state-storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private stateStorageService: StateStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const serverApiUrl = this.applicationConfigService.getEndpointFor('');
    // if (!request.url || (request.url.startsWith('http') && !(serverApiUrl && request.url.startsWith(serverApiUrl)))) {
    //   return next.handle(request);
    // }

    // const token: string | null = this.stateStorageService.getAuthenticationToken();
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    // }
    return next.handle(request);
  }
}
