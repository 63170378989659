import { NgModule } from "@angular/core";
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatPaginatorModule  } from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';


@NgModule({
  imports: [
    MatDialogModule, 
    MatButtonToggleModule, 
    MatButtonToggleModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule, 
    MatNativeDateModule
  ] ,
  exports:[ 
    MatDialogModule,
     MatButtonToggleModule, 
     MatButtonToggleModule,
     MatTabsModule,
     MatTableModule,
     MatSortModule,
     MatPaginatorModule,
     MatAutocompleteModule,
     MatInputModule,
     MatProgressBarModule,
     MatProgressSpinnerModule,
     MatFormFieldModule,
     MatDatepickerModule, 
     MatNativeDateModule
    ] 
})
export class MaterialModule{


}