import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpXSRFInterceptor implements HttpInterceptor {

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const respHeaderName = 'X-CSRF-TOKEN';
    const respHeaderNameUnder = '_csrf';
    let token = this.tokenExtractor.getToken() as string;
    if (token !== null ) {
      req = req.clone({ headers: req.headers.set(respHeaderName, token) });
      req = req.clone({ headers: req.headers.set(respHeaderNameUnder, token) });
    }
    return next.handle(req);
  }
}

